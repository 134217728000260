$(
	function() {
		splashPage.initialize();
	}
);
var splashPage = {
	_var: {
		initIsDone: false
	},
	initialize: function() {
		if (splashPage._var.initIsDone) {
			return false;
		}
        splashPage._var.initIsDone = true;
		$('a').each(
			function() {
				var $this = $(this);
				var href = $this.attr('href');
				if ($this.hasClass('udu__go-en')) {
					$this.attr('href', app.ajaxProcessURLs['en']);
				} else if ($this.hasClass('udu__go-fr')) {
					$this.attr('href', app.ajaxProcessURLs['fr']);
				}
				if (href && String(href).indexOf('__storeID') > 0) {
					$this.data('href', href)
						.attr('href', '');
					$this.click(
						function(e) {
							var $this = $(this);
							var href = $this.data('href');
							var tmp = href.match(/__storeID=(\d+)/);
							if (!tmp || !tmp[1]) {
								app.alert('Cannot define Store ID.');
							}
							app.blockWindow(
								true,
								true,
								function() {
									app.getInfo(
										'get-cross-id',
										{
											storeId: tmp[1]
										},
										function(data) {
											data = JSON.parse(data);
											if (!data.id) {
												app.alert('You are not logged into the system.');
												return false;
											}
											document.location = href.replace(/__storeID=(\d+)/, data.id);
										}
									)
								}
							);
							e.preventDefault();
						}
					);
				} else {
                    if (!$this.hasClass('no-unblock')) {
                        $this.mousedown(
                            function() {
                                splashPage.unblock()
                            }
                        );
                    }
                    if ($this.hasClass('current-page')) {
                        $this.attr('href', String(document.location))
                    }
				}
			}
		);
	},
	createCookie : function(name,value,days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			var expires = "; expires=" + date.toGMTString();
		} else {
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=/";
	},
	unblock : function() {
		splashPage.createCookie('splashPageUnblocked', 'Y');
	}
};

